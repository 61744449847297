<!-- 接单 -->

<template>
  <div class="taking-order-dialog">
    <w-dialog ref="dialogRef" title="接单确认" width="60%" top="5vh" :confirmText="'确认接单'" @wConfirm="handleSure">
      <div>
        <div class="flex-end">
          <el-button type="primary" class="add-btn" @click="onContinueBtn">继续添加</el-button>
        </div>

        <div>
          <div v-for="(item, i) in takingOrderDatas" :key="i">
            <div class="division-line" v-if="i !== 0"></div>
            <el-row :gutter="100">
              <el-col :span="12" class="l-m-b">
                <div class="accept-title">车牌</div>
                <el-input v-model="item.plate" clearable placeholder="请输入接单车牌"></el-input>
              </el-col>

              <el-col :span="12" class="l-m-b">
                <div class="accept-title">司机</div>
                <el-input v-model="item.driver" clearable placeholder="请输入接车司机"></el-input>
              </el-col>

              <el-col :span="12" class="l-m-b">
                <div class="accept-title">电话</div>
                <el-input v-model="item.tel" clearable placeholder="请输入接车司机电话"></el-input>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, reactive, } from "vue";
  import { BasicApi } from "@/plugins/api.js";
  import { ElMessage, } from "element-plus";

  export default {
    emits: ["submit"],
    setup(props, { emit }) {
      const dialogRef = ref(null);  // 弹框对象
      const takingOrderDatas = ref([{ plate: '', driver: '', tel: '' },]);
      /**
       * 
       * 打开对话框
       * 
       * **/
      const openDialog = () => {
        takingOrderDatas.value = [{ plate: '', driver: '', tel: '' },];
        dialogRef.value.show();
      }
      /**
       * 
       * 关闭对话框
       * 
       * **/
      const closeDialog = () => {
        dialogRef.value.close();
      }
      /**
       * 
       * 打开对话框加载状态
       * 
       * **/
      const closeDialogLoading = () => {
        dialogRef.value.isLoading = false;
      }
      /**
       * 
       * 继续添加按钮
       * 
       * **/
      const onContinueBtn = () => {
        takingOrderDatas.value.push({ plate: '', driver: '', tel: '' });
      }
      /**
       * 
       * 确认按钮
       * 
       * **/
      const handleSure = () => {
        for (let i = 0; i < takingOrderDatas.value.length; i++) {
          if (!takingOrderDatas.value[i].plate) {
            ElMessage.error('请输入接单车牌！');
            return false;
          }
          if (!takingOrderDatas.value[i].driver) {
            ElMessage.error('请输入接车司机！');
            return false;
          }
          if (!takingOrderDatas.value[i].tel) {
            ElMessage.error('请输入接车司机电话！');
            return false;
          }
        }
        dialogRef.value.isLoading = true;
        emit("submit", takingOrderDatas.value);
      }

      return {
        openDialog,
        dialogRef,
        closeDialog,
        closeDialogLoading,
        takingOrderDatas,
        onContinueBtn,
        handleSure,
      };
    },
  };
</script>

<style lang="scss">
  .taking-order-dialog {
    .l-m-b {
      margin-bottom: 15px;
    }

    .add-btn {
      padding: 8px 10px;
      min-height: 32px;
      max-height: 32px;
      background-color: var(--theme-color) !important;
      color: var(--text-white-color) !important;
      border: 1px solid var(--theme-color) !important;
      width: 80px;

      &:hover {
        background-color: var(--theme-color) !important;
        color: var(--text-white-color) !important;
        border: 1px solid var(--theme-color) !important;
      }
    }

    .accept-title {
      color: #5D6066;
      margin: 15px 0;
    }

    .division-line {
      height: 1px;
      background-color: #E6E8EC;
      margin: 10px 0;
    }
  }
</style>