<!-- 订单详情 -->

<template>
  <div class="order-info-dialog">
    <el-dialog v-model="dialogVisible" top="5vh" title="订单详情" width="60%" center>
      <div>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="flex align-center">
              <div class="order-top-title">支付状态</div>
              <div class="status-text" :class="statusCustom(detailInfo.or_pay_status, 'pay')">
                {{ detailInfo.or_status_text }}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="flex align-center">
              <div class="order-top-title">订单状态</div>
              <div class="status-text" :class="statusCustom(detailInfo.oc_status, 'order')">
                {{ detailInfo.oc_status_text }}</div>
            </div>
          </el-col>
        </el-row>

        <div class="route-infor">
          <div class="route-infor-title">{{ detailInfo.mcr_name }}</div>
          <el-row :gutter="20">
            <el-col :span="12" class="s-m-b">
              <div class="flex">
                <div class="order-top-title">运营时段</div>
                <div class="order-top-content">{{ detailInfo.run_time }}</div>
              </div>
            </el-col>
            <el-col :span="12" class="s-m-b">
              <div class="flex">
                <div class="order-top-title">咨询电话</div>
                <div class="order-top-content">{{ detailInfo.mcr_tel }}</div>
              </div>
            </el-col>
            <el-col :span="24" class="s-m-b">
              <div class="flex">
                <div class="order-top-title">路线描述</div>
                <textarea v-model="detailInfo.route_desc" class="order-rule-text" readonly :rows="1"></textarea>
              </div>
            </el-col>
            <el-col :span="24" class="s-m-b">
              <div class="flex">
                <div class="order-top-title">规则说明</div>
                <textarea v-model="detailInfo.rule_desc" class="order-rule-text" readonly :rows="1"></textarea>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="route-infor">
          <div class="route-infor-title">{{ detailInfo.subscribe_type == '1' ? '按人约车' : '直接包车' }}</div>
          <el-row :gutter="20">
            <el-col :span="12" class="s-m-b">
              <div class="flex">
                <div class="order-top-title">乘车人</div>
                <div class="order-top-content">{{ detailInfo.number }}人</div>
              </div>
            </el-col>
            <el-col :span="12" class="s-m-b">
              <div class="flex">
                <div class="order-top-title">联系人</div>
                <div class="order-top-content">{{ detailInfo.contact_people }}</div>
              </div>
            </el-col>
            <el-col :span="12" class="s-m-b">
              <div class="flex">
                <div class="order-top-title">联系电话</div>
                <div class="order-top-content">{{ detailInfo.contact_tel }}</div>
              </div>
            </el-col>
            <el-col :span="12" class="s-m-b">
              <div class="flex">
                <div class="order-top-title">用车时间</div>
                <div class="order-top-content">
                  <div>
                    自
                    {{ detailInfo.subscribe_stime }}
                    至
                    {{ detailInfo.subscribe_etime }}
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="24" class="s-m-b">
              <div class="flex">
                <div class="order-top-title">备注说明</div>
                <div class="order-top-content">{{ detailInfo.desc }}</div>
              </div>
            </el-col>
            <!-- 已支付已接单状态、已完成显示 -->
            <el-col :span="24" class="s-m-b"
              v-if="detailInfo.oc_status == '3' || (detailInfo.or_pay_status == '2' && detailInfo.oc_status == '2')">
              <div class="flex" style="flex-wrap: wrap;">
                <div class="order-top-title">车辆信息</div>
                <div v-for="(item, i) in detailInfo.car_driver" :key="i">
                  <div class="vehicle-info">
                    <span>{{ item.ocd_confirm_plates }}</span>
                    <span>-{{ item.ocd_confirm_driver }}</span>
                    <span>-{{ item.ocd_confirm_tel }}</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="route-infor">
          <div class="route-infor-title">订单信息</div>
          <el-row :gutter="20">
            <el-col :span="12" class="s-m-b">
              <div class="flex">
                <div class="order-top-title">订单金额</div>
                <div class="order-top-content">{{ detailInfo.or_money }}</div>
              </div>
            </el-col>
            <el-col :span="12" class="s-m-b">
              <div class="flex">
                <div class="order-top-title">支付时间</div>
                <div class="order-top-content">{{ detailInfo.pay_time ? detailInfo.pay_time : '-' }}</div>
              </div>
            </el-col>
            <!-- 订单状态已完成显示 -->
            <el-col :span="12" class="s-m-b" v-if="detailInfo.oc_status == '3'">
              <div class="flex">
                <div class="order-top-title">完成时间</div>
                <div class="order-top-content">{{ detailInfo.complete_time }}</div>
              </div>
            </el-col>
            <!-- 支付状态已取消或已退款显示 -->
            <el-col :span="12" class="s-m-b" v-if="detailInfo.or_pay_status == '5'">
              <div class="flex">
                <div class="order-top-title">退款金额</div>
                <div class="order-top-content">{{ detailInfo.refund_order.orr_refund_money }}</div>
              </div>
            </el-col>
            <el-col :span="12" class="s-m-b" v-if="detailInfo.or_pay_status == '5'">
              <div class="flex">
                <div class="order-top-title">退款时间</div>
                <div class="order-top-content">{{ detailInfo.refund_order.orr_refund_pay_time }}</div>
              </div>
            </el-col>
            <el-col :span="24" class="s-m-b" v-if="detailInfo.or_pay_status == '3' || detailInfo.or_pay_status == '5'">
              <div class="flex">
                <div class="order-top-title">退款原因</div>
                <div class="order-top-content">{{ detailInfo.refuse_reason }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <template #footer>
        <!-- 已支付待接单 -->
        <div v-if="detailInfo.or_pay_status == '2' && detailInfo.oc_status == '1'">
          <el-button @click="onClickBtn('refuse')" type="primary" class="cancle" plain round :loading="isLoading"
            style="width:120px">拒接
          </el-button>
          <el-button class="confirm" @click="onClickBtn('accept')" round :loading="isLoading" :disabled="isLoading"
            style="width:120px">接单
          </el-button>
        </div>
        <!-- 已支付已接单 -->
        <div v-if="detailInfo.or_pay_status == '2' && detailInfo.oc_status == '2'">
          <el-button class="complete-btn" @click="onClickBtn('compl')" round :loading="isLoading" :disabled="isLoading">
            完成</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import { ref, } from "vue";
  import { VipApi, } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";

  export default {
    emits: ["submit"],
    setup(props, { emit }) {

      const dialogVisible = ref(false);
      const dialogRef = ref(null);  // 弹框对象
      const currentRow = ref('');  // 当前行数据
      const detailInfo = ref('');  // 详情数据
      const isLoading = ref(false);
      /**
       * 
       * 打开对话框
       * 
       * **/
      const openDialog = (row) => {
        getOrderInfo(row);
        dialogVisible.value = true;
      }
      /**
       * 
       * 关闭对话框
       * 
       * **/
      const closeDialog = () => {
        dialogVisible.value = false;
      }
      /**
       * 
       * 打开对话框加载状态
       * 
       * **/
      const closeDialogLoading = () => {
        isLoading.value = false;
      }
      /**
       * 
       * 获取详情数据
       * 
       * **/
      const getOrderInfo = (row) => {
        currentRow.value = row;
        VipApi.getOrderInfo({ or_sn: row.or_sn }).then(res => {
          if (res.Code == 200) {
            detailInfo.value = res.Data;
          } else {
            ElMessage.error(res.Message);
          }
        })
      }
      /**
      * 
      * 点击操作按钮
      * 
      * **/
      const onClickBtn = (type) => {
        emit("submit", currentRow.value, type);
      }
      /**
      * 
      * 状态
      * 
      * **/
      const statusCustom = (status, type) => {
        let payment_color = '';  // 支付状态 1-待交 2-已支付 3-订单取消 5-已退款
        let status_color = '';  // 订单状态 1=待接单，2=已接单，3=已完成，8=已取消，9=未接单
        switch (status) {
          case 1:
            payment_color = 'btn-red';
            status_color = 'btn-orange';
            break;
          case 2:
            payment_color = 'btn-green';
            status_color = 'btn-yellow';
            break;
          case 3:
            payment_color = 'btn-grey';
            status_color = 'btn-blue';
            break;
          case 5:
            payment_color = 'btn-black';
            break;
          case 8:
            status_color = 'btn-black';
            break;
          case 9:
            status_color = 'btn-grey';
            break;
        }
        if (type == 'pay') {
          return payment_color;
        } else {
          return status_color;
        }
      }

      return {
        dialogVisible,
        openDialog,
        dialogRef,
        closeDialog,
        closeDialogLoading,
        currentRow,
        detailInfo,
        getOrderInfo,
        statusCustom,
        isLoading,
        onClickBtn,
      };
    },
  };
</script>

<style lang="scss">
  .order-info-dialog {
    .xs-m-b {
      margin-bottom: 5px;
    }

    .s-m-b {
      margin-bottom: 10px;
    }

    .cancle {
      border: 2px solid var(--theme-color);
      padding: 10px 45px;
      color: var(--theme-color);
      background-color: #ffffff;
    }

    .order-top-title {
      color: #92979E;
      width: 80px;
      text-align: left;
      // margin-bottom: 10px;
      flex-shrink: 0;
    }

    .route-infor {
      background-color: #F8F9FA;
      border-radius: 8px;
      padding: 15px;
      margin-top: 20px;
    }

    .route-infor-title {
      color: #262C30;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .order-top-content {
      color: #262C30;
    }

    .order-rule-text {
      border: none;
      font-family: Source Han Sans CN;
      font-size: 14px;
      width: 100%;
      background-color: #F8F9FA;
    }

    .complete-btn {
      background-color: var(--theme-color) !important;
      color: var(--text-white-color) !important;
      border: 1px solid var(--theme-color) !important;
      width: 120px;

      &:hover {
        background-color: var(--theme-color) !important;
        color: var(--text-white-color) !important;
        border: 1px solid var(--theme-color) !important;
      }
    }

    .vehicle-info {
      background-color: var(--theme-color);
      color: var(--text-white-color);
      border-radius: 4px;
      font-size: 12px;
      padding: 0 5px;
      height: 26px;
      line-height: 26px;
      margin: 0 10px 5px 0;
    }
  }
</style>