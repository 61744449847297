<!-- 拒接 -->

<template>
  <div class="refuse-order-dialog">
    <el-dialog v-model="dialogRef" top="20vh" title="拒接确认" width="35%" center>
      <div>
        <div class="accept-title">拒接理由</div>
        <el-input type="textarea" rows="6" placeholder="请输入无法接单原因" clearable v-model.trim="reason">
        </el-input>
        <div class="explain-text">特别说明：确认拒绝后订单将自动退款给客户</div>
      </div>

      <template #footer>
        <el-button class="foot-btn" @click="handleSure" :loading="isLoading" :disabled="isLoading">确认拒绝
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import { ref, reactive, } from "vue";
  import { BasicApi } from "@/plugins/api.js";
  import { ElMessage, } from "element-plus";

  export default {
    emits: ["submit"],
    setup(props, { emit }) {
      const dialogRef = ref(false);  // 弹框对象
      const isLoading = ref(false);  // 加载状态
      const reason = ref('');  // 拒接理由
      /**
       * 
       * 打开对话框
       * 
       * **/
      const openDialog = () => {
        reason.value = '';
        dialogRef.value = true;
      }
      /**
       * 
       * 关闭对话框
       * 
       * **/
      const closeDialog = () => {
        dialogRef.value = false;
      }
      /**
       * 
       * 打开对话框加载状态
       * 
       * **/
      const closeDialogLoading = () => {
        isLoading.value = false;
      }
      /**
       * 
       * 确认按钮
       * 
       * **/
      const handleSure = () => {
        isLoading.value = true;
        emit("submit", reason.value);
      }

      return {
        openDialog,
        dialogRef,
        closeDialog,
        closeDialogLoading,
        handleSure,
        reason,
        isLoading,
      };
    },
  };
</script>

<style lang="scss">
  .refuse-order-dialog {
    .l-m-b {
      margin-bottom: 15px;
    }

    .foot-btn {
      padding: 8px 10px;
      min-height: 32px;
      max-height: 32px;
      background-color: var(--theme-color) !important;
      color: var(--text-white-color) !important;
      border: 1px solid var(--theme-color) !important;
      width: 80px;

      &:hover {
        background-color: var(--theme-color) !important;
        color: var(--text-white-color) !important;
        border: 1px solid var(--theme-color) !important;
      }
    }

    .accept-title {
      color: #5D6066;
      margin: 15px 0;
    }

    .el-textarea__inner {
      background-color: var(--search-uncheck-bg-color);
      border: none;
      color: var(--text-color);
      font-weight: 550;

      &::placeholder {
        font-weight: normal;
      }
    }

    .explain-text {
      color: #FA7D00;
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
</style>