<!-- 会员服务-服务订单 -->

<template>
  <div class="service-orders main-cnt">
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 280px)" :apiName="VipApi.getOrderLists"
        :columns="tableColumns" :filters="filters" @onDetails="onDetails">
        <template #payStatus="{ row }">
          <div class="status-text" :class="statusCustom(row.or_pay_status, 'pay')">{{ row.status_text }}</div>
        </template>

        <template #status="{ row }">
          <div class="status-text" :class="statusCustom(row.oc_status, 'order')">{{ row.status }}</div>
        </template>

        <!-- 接单 -->
        <template #takingOrders="{ row }">
          <template
            v-if="row.or_pay_status == '2' && row.oc_status == '1' && authData.indexOf('n_He7xtlPruQrfSxFApq6VdQQG6N') != -1">
            <el-button class="theme-font-btn" @click="onClickBtn(row, 'accept')">接单</el-button>
          </template>
        </template>

        <!-- 拒接 -->
        <template #refuse="{ row }">
          <template
            v-if="row.or_pay_status == '2' && row.oc_status == '1' && authData.indexOf('n_VHfOEEbfngnlk6JEYuypjU4W6g') != -1">
            <el-button class="red-font-btn" @click="onClickBtn(row, 'refuse')">拒接</el-button>
          </template>
        </template>

        <!-- 完成 -->
        <template #complete="{ row }">
          <template
            v-if="row.or_pay_status == '2' && row.oc_status == '2' && authData.indexOf('n_jZvyqt5jOGZdCEIcDPTtJzhxrR') != -1">
            <el-button class="theme-font-btn" @click="onClickBtn(row, 'compl')">完成</el-button>
          </template>
        </template>
      </common-table>
    </div>

    <!-- 详情 -->
    <OrderDetails ref="infoDialogRef" @submit="onClickBtn"></OrderDetails>

    <!-- 接单确认 -->
    <TakingOrders ref="acceptRef" @submit="onConfirmAccept"></TakingOrders>

    <!-- 拒接 -->
    <RefusalAccept ref="refuseRef" @submit="onConfirmRefuse"></RefusalAccept>

    <!-- 完成 -->
    <!-- <CompleteOrder ref="complRef" @submit="onConfirmCompl"></CompleteOrder> -->
  </div>
</template>

<script setup>
  import { ref, onMounted, watch, computed, } from "vue";
  import { VipApi, } from "@/plugins/api.js";
  import { ElMessage, ElMessageBox, } from "element-plus";
  import { useStore } from "vuex";
  import OrderDetails from "../components/OrderDetails.vue";
  import TakingOrders from "../components/TakingOrders.vue";
  import RefusalAccept from "../components/RefusalAccept.vue";
  // import CompleteOrder from "../components/CompleteOrder.vue";
  import dayjs from "dayjs";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /** 表格对象 */
  const tableRef = ref(null);
  // 表格筛选
  const filters = ref([
    {
      filterType: "search",
      name: "keywords",
      value: "",
      placeholder: "请输入会员手机号",
    },
    {
      filterType: "select",
      name: "or_type",
      value: "",
      placeholder: "请选择服务类型",
      options: [],
      lab: "name",
      val: "value",
    },
    {
      filterType: "select",
      name: "pay_status",
      value: "",
      placeholder: "请选择支付状态",
      options: [
        { value: 1, name: '待支付' },
        { value: 2, name: '已支付' },
        { value: 3, name: '已取消' },
        { value: 5, name: '已退款' },
      ],
      lab: "name",
      val: "value",
    },
    {
      filterType: "select",
      name: "status",
      value: "",
      placeholder: "请选择订单状态",
      options: [],
      lab: "name",
      val: "value",
    },
    {
      filterType: "date",
      name: "stime",
      name2: "etime",
      value: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      placeholder: "请选择支付时间",
      type: "daterange",
    },
  ]);
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "or_sn",
      label: "订单号",
      minWidth: 130,
    },
    {
      prop: "or_type_label",
      label: "服务类型",
    },
    {
      prop: "m_mobile",
      label: "会员手机",
    },
    {
      prop: "or_money",
      label: "订单金额",
    },
    {
      type: "customRender",
      prop: "payStatus",
      label: "支付状态",
      minWidth: 100,
    },
    {
      prop: "or_pay_time",
      label: "支付时间",
    },
    {
      type: "customRender",
      prop: "status",
      label: "订单状态",
      minWidth: 100,
    },
    {
      prop: "or_refund_money",
      label: "退款金额",
    },
    {
      prop: "or_refund_time",
      label: "退款时间",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 280,
      bottons: [
        {
          name: "详情",
          action: "onDetails",
          className: "theme-font-btn",
          token: "n_JyONCnFudrkYo0pUqFuR1eIUph",
        },
        {
          type: "customRender",
          name: "接单",
          action: "takingOrders",
        },
        {
          type: "customRender",
          name: "拒接",
          action: "refuse",
        },
        {
          type: "customRender",
          name: "完成",
          action: "complete",
        },
      ],
    },
  ]);
  const infoDialogRef = ref(null);  // 详情对象
  const currentRow = ref('');  // 当前行数据
  const acceptRef = ref(null);  // 接单对象
  const refuseRef = ref(null);  // 拒接对象
  // const complRef = ref(null);  // 完成对象
  /**
  * 
  * 获取服务订单类型
  * 
  * **/
  const getServiceOrderType = () => {
    VipApi.getServiceOrderType().then(res => {
      if (res.Code == 200) {
        filters.value[1].options = res.Data;
      } else {
        ElMessage.error(res.Message);
      }
    })
  }
  /**
  * 
  * 获取订单状态
  * 
  * **/
  const getServiceOrderStatus = () => {
    VipApi.getServiceOrderStatus().then(res => {
      if (res.Code == 200) {
        filters.value[3].options = res.Data;
      } else {
        ElMessage.error(res.Message);
      }
    })
  }
  /**
  * 
  * 详情按钮
  * 
  * **/
  const onDetails = (row) => {
    infoDialogRef.value.openDialog(row);
  }
  /**
  * 
  * 点击操作按钮
  * 
  * **/
  const onClickBtn = (row, type) => {
    currentRow.value = row;
    if (type == 'accept') {
      // 接单
      acceptRef.value.openDialog();
    } else if (type == 'refuse') {
      // 拒接
      refuseRef.value.openDialog();
    } else if (type == 'compl') {
      // 完成
      ElMessageBox.confirm("是否确认完成?", "完成确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      })
      .then(() => {
        onConfirmCompl();
      })
      .catch(() => {});
    }
  }
  /**
  * 
  * 接单确定按钮
  * 
  * **/
  const onConfirmAccept = (data) => {
    let params = {
      or_sn: currentRow.value.or_sn,
      driver: data,
    };
    VipApi.acceptCarmeetOrder(params).then(res => {
      acceptRef.value.closeDialogLoading();
      if (res.Code == 200) {
        infoDialogRef.value.getOrderInfo(currentRow.value);
        acceptRef.value.closeDialog();
        tableRef.value.tableLoad();
        ElMessage.success('接单成功！');
      } else {
        ElMessage.error(res.Message);
      }
    })
  }
  /**
  * 
  * 拒接确定按钮
  * 
  * **/
  const onConfirmRefuse = (data) => {
    let params = {
      or_sn: currentRow.value.or_sn,
      reason: data,
    };
    VipApi.refuseCarmeetOrder(params).then(res => {
      refuseRef.value.closeDialogLoading();
      if (res.Code == 200) {
        infoDialogRef.value.getOrderInfo(currentRow.value);
        refuseRef.value.closeDialog();
        tableRef.value.tableLoad();
        ElMessage.success('拒绝成功！');
      } else {
        ElMessage.error(res.Message);
      }
    })
  }
  /**
  * 
  * 完成确定按钮
  * 
  * **/
  const onConfirmCompl = () => {
    let params = {
      or_sn: currentRow.value.or_sn,
    };
    VipApi.completeCarmeetOrder(params).then(res => {
      if (res.Code == 200) {
        infoDialogRef.value.getOrderInfo(currentRow.value);
        tableRef.value.tableLoad();
        ElMessage.success('操作成功！');
      } else {
        ElMessage.error(res.Message);
      }
    })
  }
  /**
  * 
  * 获取当前日期时间戳
  * 
  * **/
  // const getTodayTimestamp = () => {
  //   const now = new Date();
  //   const year = now.getFullYear();
  //   const month = now.getMonth() + 1;
  //   const day = now.getDate();
  //   const timestamp = Date.parse(new Date(year, month - 1, day));
  //   filters.value[4].value = [timestamp, timestamp];
  // }
  /**
  * 
  * 状态
  * 
  * **/
  const statusCustom = (status, type) => {
    let payment_color = '';  // 支付状态 1-待交 2-已支付 3-订单取消 5-已退款
    let status_color = '';  // 订单状态 1=待接单，2=已接单，3=已完成，8=已取消，9=未接单
    switch (status) {
      case 1:
        payment_color = 'btn-red';
        status_color = 'btn-orange';
        break;
      case 2:
        payment_color = 'btn-green';
        status_color = 'btn-yellow';
        break;
      case 3:
        payment_color = 'btn-grey';
        status_color = 'btn-blue';
        break;
      case 5:
        payment_color = 'btn-black';
        break;
      case 8:
        status_color = 'btn-black';
        break;
      case 9:
        status_color = 'btn-grey';
        break;
    }
    if (type == 'pay') {
      return payment_color;
    } else {
      return status_color;
    }
  }

  onMounted(() => {
    getServiceOrderType();
    getServiceOrderStatus();
    tableRef.value.tableLoad();
  });
</script>
<style lang="scss">
  .service-orders {
    font-family: "Source Han Sans CN";

    .s-m-r {
      margin-right: 10px;
    }

    .content {
      padding: 15px 20px 20px;
    }

    .infor-title {
      color: #5D6066;
      width: 80px;
      text-align: left;
      margin-bottom: 10px;
    }

    .infor-content {
      color: #262C30;
    }
  }
</style>